.preloader {
  background:  linear-gradient(180deg, #383838 0%, #010101 100%);
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

/* .loader {
  margin-top: 15px;  
  border: 10px solid #f3f3f3; 
  border-top: 10px solid #FFE41B; 
  border-radius: 50%;
  width: 40px;
  height: 35px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} */
