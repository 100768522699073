body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  color: #1e1e1e;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.text-left{
  text-align: left !;
}
.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Set a minimum height for the layout to take up the full viewport height */
  width: 100vw;
}

.top {
  flex: 0; /* Header and subheader will not scroll */
  position: sticky;
  top: 0 !important;
  z-index: 100; /* Set a z-index to make the header and subheader appear above the content */
}

.content {
  background-color: #eee;
  flex: 1 1;
  /* display: table; */
}


.colorName{
  color:"#3b5160"
}

.table_container_scroll {
  max-height: 80vh; 
  overflow-y: auto;  
}

.disabled{
  user-select: none;
  pointer-events: none;
}

.holderName {
  margin: 0 !important;
  border-width: 1px;
  box-shadow: none;
  padding: 4px;
  width: 180px;
}
.holderName:focus {
  outline: none;
  box-shadow: none;
  border: 1px #aaa solid;
}
.mtdata {
  margin-top: 5px;
}

.w34 {
  width: 34% !important;
}
.diXyas1 {
  box-shadow: none !important;
  padding: 4px !important;
}
.diXyas1:focus {
  box-shadow: none !important;
  outline: 0;
  padding: 4px;
}
.addBorder {
  border-bottom: 0px solid #e0e6e6 !important;
  background: #243a48;
  padding: 0 10px;
}
.prevImg {
  width: 450px;
}
.tansitsdel {
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.withdrawal {
  width: 200px !important;
}
.p0 {
  padding: 0 !important;
}
/* .ml-2{
  margin-left: 10px !important;
} */

.inptam {
  display: flex;
  align-items: center;
  margin-top: 3px;
  font-size: 14px;
  width: 72px !important;
}

.mt12{
   margin-top:7px;
}

.css-1r2bn3-JoySvgIcon-root{
  fill:#fff !important;
  height: 27px;
  width: 28px;
}

.bankDetails{
  display: flex
  ;
      align-items: center;
      background-color: #1e1e1e;
      color: #fff;
      padding: 7px;
      width: 100%;
      font-weight: bold;
      font-size: 14px;
      flex-shrink: 1;
      padding: 0 8px 0 5px;
      line-height: 1.42857143;
      height: 34px;
      margin-bottom: 12px;
}

@media (hover: hover) {
  .css-19tjg98-JoyModalClose-root:hover {
      --Icon-color: currentColor;
      background-color: #000 !important;
      color: #fff !important;
  }
}
.mr0{
  margin-right: 0px !important;
  width: 103% !important;
}
.walletf{
  cursor: pointer;
  height: 20px !important;
  display: flex !important
;
align-items: center !important;
font-weight: bold !important;
letter-spacing: 0.1rem;
background-color:transparent !important;
}
.depo{
  color: #fff !important;
}
.withd{
  color: #de352c !important;
}

/* for wallet dd */

.walletdd{
  height: 25px !important;
  border-bottom: 1px solid #e0e6e6 !important
}
.depositinput{
  width: 230px !important;
}
.pointer-event-none {
  pointer-events: none !important;
}
.ml-11{
  margin-left: 11px !important;
}

.transact{
 width:  119.4px !important
}

.yellow{
  color:rgb(163, 128, 33);
}

.mtb0{
  margin-top: -3.7px !important;
}