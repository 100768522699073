:root {
  --primary:#ff00a7;
  --bg:#eee;
  --whitecolor:#fff
  --color: #1e1e1e;
  --common-dark: #000;
  --common-text: #212529;
  --primary-linear: linear-gradient(180deg,#ff00a7 1%,#ff00a7);
  --bg-linear: linear-gradient(180deg, #383838 0%, #010101 100%);
  --bg1-liner: linear-gradient(180deg, #2a3a43 27%, #1c282d 83%);
  --nav-select: #d72786;
  --border: #7e97a7;
  --body: #eee;
  --head: #2a3a43;
  --side-bar: #fff;
  --side-bar1: #3b5160;
  --text1: #212529;
  --text1: #222;
  --navhover:#d72786
}


